import { ClientsPage, MetricsPage } from './pages';

export const Routes = [
	{
		key: 0,
		path: '/metrics',
		label: 'Metrics',
		component: MetricsPage,
		icon: 'fas fa-tachometer-alt',
		exact: true,
	},
	{
		key: 1,
		path: '/clients',
		label: 'Clients',
		component: ClientsPage,
		icon: 'fas fa-users',
		exact: true,
	},
	{
		key: 2,
		path: '/invoices',
		label: 'Invoices',
		component: () => 'Invoices Page',
		icon: 'fas fa-file-invoice',
		exact: true,
	},
	{
		key: 3,
		path: '/traffic',
		label: 'Traffic',
		component: () => 'Web Traffic Page',
		icon: 'fas fa-traffic-light',
		exact: true,
	},
];
