import { Routes } from '../routes';

export const DashboardLayout = ({ children }) => {
	return (
		<div>
			<div className='container-fluid'>
				<div>
					<nav
						id='sidebarMenu'
						className='col-lg-2 d-md-block bg-light sidebar collapse'
						style={{
							backgroundColor: 'rgb(25, 27, 31)',
						}}
					>
						<div
							className='position-sticky pt-3 sidebar-sticky'
							style={{
								marginLeft: '10px',
								marginTop: '10px',
							}}
						>
							<ul
								className='nav flex-column'
								id='sidebarLinks'
								style={{ marginBottom: '15px' }}
							>
								{Routes.map((route) => (
									<a
										key={route.key}
										href={route.path}
										style={{
											textDecoration: 'none',
											color: '#eee',
											marginBottom: '10px',
										}}
									>
										<p
											style={{
												paddingLeft: '16px',
												marginBottom: '0',
												paddingBottom: '10px',
												fontSize: '24px',
												fontWeight: '600',
											}}
										>
											<i
												className={route.icon}
												style={{
													width: '40px',
												}}
											></i>{' '}
											{route.label}
										</p>
									</a>
								))}
							</ul>
						</div>
					</nav>

					<div style={{ marginLeft: '300px' }}>{children}</div>
				</div>
			</div>
		</div>
	);
};
