import { useContext } from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import { AuthContext } from '../contexts';
import { border, fluid, text } from '../exports';

export const DefaultLayout = ({ children }) => {
	const { authencated, token, logoutUser } = useContext(AuthContext);

	const [Token] = token;

	const [Authencated] = authencated;

	return (
		<div
			style={{
				backgroundColor: 'rgb(41,41,41)',
				height: '100vh',
				width: '100vw',
			}}
		>
			<div
				style={{
					position: 'absolute',
					width: '100vw',
					zIndex: '4',
				}}
			>
				<Navbar
					bg='light'
					sticky='top'
					style={{
						borderBottom: border.size + ' solid ' + border.color,
						height: '100px',
						color: text,
					}}
				>
					<Container
						fluid={fluid}
						className={fluid ? 'navbarContainer' : null}
						style={{
							marginTop: '5px',
							marginBottom: '5px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
							}}
						>
							<a
								href='/'
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '0',
									margin: '0',
									textDecoration: 'none',
								}}
							>
								<Navbar.Brand
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: '0',
										margin: '0',
									}}
								>
									<img
										src={'/logo.png'}
										style={{
											height: '50px',
											marginRight: '20px',
											borderRadius: '5px',
										}}
									/>

									<div
										style={{
											paddingTop: '5px',
											paddingBottom: '5px',
										}}
									>
										<h1
											className='h4'
											style={{
												padding: '0',
												margin: '0',
												color: text,
											}}
										>
											{'Breaking Point Software'}
										</h1>

										<p
											style={{
												margin: '0',
												padding: '0',
												color: text,
											}}
										>
											Software Consulting & Web
											Development
										</p>
									</div>
								</Navbar.Brand>
							</a>

							{Authencated && Token && (
								<Button
									href='/logout'
									style={{ marginLeft: 'auto' }}
								>
									Logout
								</Button>
							)}
						</div>
					</Container>
				</Navbar>

				<div
					style={{
						width: '100vw',
						height: 'calc(100vh - 100px)',
						overflow: 'hidden',
					}}
				>
					{children}
				</div>
			</div>

			{/* {FinishedLoading ? (
		Token && FinishedLoading ? (
			<>
				<div
					style={{
						position: 'absolute',
						zIndex: '4',
					}}
				>
					<div className='container-fluid'>
						<div>
							<nav
								id='sidebarMenu'
								className='col-lg-2 d-md-block bg-light sidebar collapse'
								style={{
									backgroundColor: 'rgb(25, 27, 31)',
								}}
							>
								<div
									className='position-sticky pt-3 sidebar-sticky'
									style={{
										marginLeft: '10px',
										marginTop: '10px',
									}}
								>
									<ul
										className='nav flex-column'
										id='sidebarLinks'
										style={{ marginBottom: '15px' }}
									>
										<a
											href='#'
											style={{
												textDecoration: 'none',
												color: '#eee',
											}}
										>
											<p
												style={{
													paddingLeft: '16px',
													marginBottom: '0',
													paddingBottom: '10px',
													fontSize: '24px',
													fontWeight: '600',
												}}
											>
												<i
													className='fas fa-users'
													style={{
														width: '40px',
													}}
												></i>{' '}
												Clients
											</p>
										</a>
									</ul>
								</div>
							</nav>
						</div>
					</div>
				</div>

				<div
					style={{
						position: 'absolute',
						zIndex: '3',
						width: '100vw',
						height: '100vh',
						backgroundColor: 'rgb(41,41,41)',
					}}
				>
					<div
						style={{
							marginLeft: '300px',
							marginTop: '100px',
						}}
					>
						<div style={{ padding: '15px' }}>
							<Card
								style={{
									backgroundColor: 'rgb(15, 15, 15)',
									borderColor: 'rgb(15, 15, 15)',
								}}
							>
								<Card.Body
									style={{
										backgroundColor: 'rgb(15, 15, 15)',
										borderColor: 'rgb(15, 15, 15)',
									}}
								>
									<div
										style={{
											display: 'flex',
										}}
									>
										<Card.Title>Clients</Card.Title>

										<div
											style={{
												marginLeft: 'auto',
											}}
										>
											<Button>Create Client</Button>
										</div>
									</div>

									<div style={{ paddingTop: '15px' }}>
										<Table borderless>
											<thead>
												<tr>
													<th
														style={tableStyling}
													>
														Client Name
													</th>
													<th
														style={tableStyling}
													>
														Phone Number
													</th>
													<th
														style={tableStyling}
													>
														Client Email
													</th>
												</tr>
											</thead>
											<tbody>
												{Clients &&
													Clients.map(
														(client, index) => (
															<tr key={index}>
																<td
																	style={
																		tableStyling
																	}
																>
																	{
																		client.name
																	}
																</td>
																<td
																	style={
																		tableStyling
																	}
																>
																	{
																		client.phone
																	}
																</td>
																<td
																	style={
																		tableStyling
																	}
																>
																	{
																		client.email
																	}
																</td>
															</tr>
														)
													)}
											</tbody>
										</Table>
									</div>
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>

				<div
					style={{
						position: 'absolute',
						zIndex: '2',
						width: '100vw',
						height: '100vh',
						backgroundColor: 'rgba(0, 0, 0, 0.6)',
					}}
				/>

				<div
					style={{
						position: 'absolute',
						zIndex: '1',
						width: '100vw',
						height: '100vh',
						backgroundImage:
							"url('https://www.breakingpoint.software/bp-background.jpg')",
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}
				/>
			</>
		) : (
			<>
				<div
					style={{
						position: 'absolute',
						zIndex: '3',
						width: '100vw',
						height: '100vh',
						backgroundColor: 'rgb(41,41,41)',
					}}
				>
					<div
						style={{
							margin: 'auto',
							marginTop: '150px',
							width: '450px',
						}}
					>
						<div style={{ padding: '15px' }}>
							<Card
								style={{
									backgroundColor: 'rgb(15, 15, 15)',
									borderColor: 'rgb(15, 15, 15)',
								}}
							>
								<Card.Body
									style={{
										backgroundColor: 'rgb(15, 15, 15)',
										borderColor: 'rgb(15, 15, 15)',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											padding: '5px',
										}}
									>
										<Card.Title
											className='h1'
											style={{ fontSize: '24px' }}
										>
											Login
										</Card.Title>
									</div>

									<div style={{ paddingTop: '15px' }}>
										<Form>
											<Form.Group>
												<Form.Label>
													Username
												</Form.Label>

												<Form.Control
													type='text'
													value={UsernameInput}
													onChange={(e) =>
														setUsernameInput(
															e.target.value
														)
													}
												/>
											</Form.Group>

											<Form.Group
												style={{
													paddingTop: '15px',
												}}
											>
												<Form.Label>
													Password
												</Form.Label>

												<Form.Control
													type='password'
													value={PasswordInput}
													onChange={(e) =>
														setPasswordInput(
															e.target.value
														)
													}
												/>
											</Form.Group>

											<div
												style={{
													paddingTop: '20px',
												}}
											>
												<Button
													onClick={login}
													style={{
														width: '100%',
														backgroundColor:
															'rgb(237, 109, 36)',
														borderColor:
															'rgb(237, 109, 36)',
													}}
													variant='secondary'
												>
													Login
												</Button>
											</div>
										</Form>
									</div>
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>

				<div
					style={{
						position: 'absolute',
						zIndex: '2',
						width: '100vw',
						height: '100vh',
						backgroundColor: 'rgba(0, 0, 0, 0.6)',
					}}
				/>

				<div
					style={{
						position: 'absolute',
						zIndex: '1',
						width: '100vw',
						height: '100vh',
						backgroundImage:
							"url('https://www.breakingpoint.software/bp-background.jpg')",
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}
				/>
			</>
		)
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				height: '100vh',
				width: '100vw',
			}}
		>
			<Spinner
				animation='border'
				role='status'
				style={{
					marginTop: '220px',
					fontSize: '30px',
					height: '125px',
					width: '125px',
				}}
			>
				<span className='visually-hidden'>Loading...</span>
			</Spinner>
		</div>
	)} */}
		</div>
	);
};
