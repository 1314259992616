import { useContext, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { postData } from '../utils';
import { AuthContext } from '../contexts';

export const LoginPage = () => {
	const [UsernameInput, setUsernameInput] = useState('');
	const [PasswordInput, setPasswordInput] = useState('');

	const { authencateUser } = useContext(AuthContext);

	function login() {
		postData('https://backend.breakingpoint.software/admins/login', {
			username: UsernameInput,
			password: PasswordInput,
		}).then((data) => {
			if (data.token) {
				authencateUser(data.token);
			} else if (data.error) {
				alert(data.error);
			}
		});
	}

	return (
		<div
			style={{
				margin: 'auto',
				marginTop: '150px',
				width: '450px',
			}}
		>
			<div style={{ padding: '15px' }}>
				<Card
					style={{
						backgroundColor: 'rgb(15, 15, 15)',
						borderColor: 'rgb(15, 15, 15)',
					}}
				>
					<Card.Body
						style={{
							backgroundColor: 'rgb(15, 15, 15)',
							borderColor: 'rgb(15, 15, 15)',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '5px',
							}}
						>
							<Card.Title
								className='h1'
								style={{ fontSize: '24px' }}
							>
								Login
							</Card.Title>
						</div>

						<div style={{ paddingTop: '15px' }}>
							<Form>
								<Form.Group>
									<Form.Label>Username</Form.Label>

									<Form.Control
										type='text'
										value={UsernameInput}
										onSubmit={login}
										onChange={(e) =>
											setUsernameInput(e.target.value)
										}
									/>
								</Form.Group>

								<Form.Group
									style={{
										paddingTop: '15px',
									}}
								>
									<Form.Label>Password</Form.Label>

									<Form.Control
										type='password'
										value={PasswordInput}
										onKeyDown={(e) => {
											if (e.key == 'Enter') login();
										}}
										onChange={(e) =>
											setPasswordInput(e.target.value)
										}
									/>
								</Form.Group>

								<div
									style={{
										paddingTop: '20px',
									}}
								>
									<Button
										onClick={login}
										style={{
											width: '100%',
											backgroundColor:
												'rgb(237, 109, 36)',
											borderColor: 'rgb(237, 109, 36)',
										}}
										variant='secondary'
									>
										Login
									</Button>
								</div>
							</Form>
						</div>
					</Card.Body>
				</Card>
			</div>
		</div>
	);
};
