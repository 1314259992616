import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { postData } from '../../utils';

export const CreateClientModal = ({ show, setShow, fetchClients, token }) => {
	const [ClientName, setClientName] = useState('');
	const [PhoneNumber, setPhoneNumber] = useState('');
	const [EmailAddress, setEmailAddress] = useState('');

	function handleClose() {
		setShow(false);
	}

	function createClient() {
		postData(
			'https://backend.breakingpoint.software/clients/create',
			// 'http://localhost:3001/clients/create',
			{
				clientName: ClientName,
				phoneNumber: PhoneNumber,
				emailAddress: EmailAddress,
			},
			token
		).then((data) => {
			if (data.error) return alert(data.error);

			handleClose();
			fetchClients();
		});
	}

	return (
		<Modal show={show} onHide={handleClose} size='lg'>
			<Modal.Header closeButton>
				<Modal.Title>Create Client</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className='mb-3' controlId='name'>
						<Form.Label>Client Name</Form.Label>
						<Form.Control
							type='text'
							value={ClientName}
							onChange={(e) => setClientName(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='phone'>
						<Form.Label>Phone Number</Form.Label>
						<Form.Control
							type='text'
							value={PhoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='phone'>
						<Form.Label>Client Email</Form.Label>
						<Form.Control
							type='text'
							value={EmailAddress}
							onChange={(e) => setEmailAddress(e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleClose}>
					Close
				</Button>
				<Button variant='primary' onClick={createClient}>
					Create
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
