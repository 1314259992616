import { useContext, useEffect, useState } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { DashboardLayout } from '../layouts/dashboardLayout';
import { tableStyling } from '../exports';
import { AuthContext } from '../contexts';
import { CreateClientModal } from '../components/modals/createClientModal';

export const ClientsPage = () => {
	const [Clients, setClients] = useState([]);

	const [ShowCreateClientModal, setShowCreateClientModal] = useState(false);

	const { authencated, token } = useContext(AuthContext);

	const [Authencated] = authencated;

	const [Token] = token;

	function fetchClients() {
		setClients([]);

		fetch(
			'https://backend.breakingpoint.software/clients?token=' + Token
			// 'http://localhost:3001/clients?token=' + Token
		).then(async (data) => {
			const { clients } = await data.json();

			setClients(clients);
		});
	}

	useEffect(() => {
		if (!Token) return;

		fetchClients();
	}, [Authencated, Token]);

	return (
		<DashboardLayout>
			<CreateClientModal
				show={ShowCreateClientModal}
				setShow={setShowCreateClientModal}
				fetchClients={fetchClients}
				token={Token}
			/>

			<div>
				<div style={{ padding: '15px' }}>
					<Card
						style={{
							backgroundColor: 'rgb(15, 15, 15)',
							borderColor: 'rgb(15, 15, 15)',
						}}
					>
						<Card.Body
							style={{
								backgroundColor: 'rgb(15, 15, 15)',
								borderColor: 'rgb(15, 15, 15)',
							}}
						>
							<div
								style={{
									display: 'flex',
								}}
							>
								<Card.Title>Clients</Card.Title>

								<div
									style={{
										marginLeft: 'auto',
									}}
								>
									<Button
										onClick={() =>
											setShowCreateClientModal(true)
										}
									>
										Create Client
									</Button>
								</div>
							</div>

							<div style={{ paddingTop: '15px' }}>
								<Table borderless>
									<thead>
										<tr>
											<th style={tableStyling}>
												Client Name
											</th>
											<th style={tableStyling}>
												Phone Number
											</th>
											<th style={tableStyling}>
												Client Email
											</th>
										</tr>
									</thead>
									<tbody>
										{Clients &&
											Clients.map((client, index) => (
												<tr key={index}>
													<td style={tableStyling}>
														{client.name}
													</td>
													<td style={tableStyling}>
														{client.phone}
													</td>
													<td style={tableStyling}>
														{client.email}
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</div>
			</div>
		</DashboardLayout>
	);
};
