import 'bootstrap/dist/css/bootstrap.min.css';

import './styling/index.css';
import './styling/styling.css';

import { createRoot } from 'react-dom/client';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { DefaultLayout } from './layouts';

import { AuthContextProvider } from './contexts';

import { Routes } from './routes';

import { LoginPage, SpinnerPage } from './pages';

const root = createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<AuthContextProvider>
			<DefaultLayout>
				<Switch>
					<Route path='/' component={SpinnerPage} exact />

					<Route path='/login' component={LoginPage} exact />

					<Route path='/logout' component={SpinnerPage} exact />

					{Routes.map((route) => (
						<Route {...route} />
					))}
				</Switch>
			</DefaultLayout>
		</AuthContextProvider>
	</BrowserRouter>
);
