export const border = {
	size: '5px',
	color: 'rgb(237, 109, 36)',
};

export const fluid = true;

export const text = '#eee';

export const tableStyling = {
	backgroundColor: 'transparent',
	color: '#eee',
};
