import { Spinner } from 'react-bootstrap';

export const SpinnerPage = () => {
	return (
		<div
			style={{
				width: '100vw',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Spinner
				animation='border'
				role='status'
				style={{
					marginTop: '125px',
					fontSize: '45px',
					height: '245px',
					width: '245px',
					color: 'rgb(15, 15, 15)',
				}}
			>
				<span className='visually-hidden'>Loading...</span>
			</Spinner>
		</div>
	);
};
