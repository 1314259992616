import { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { DashboardLayout } from '../layouts/dashboardLayout';
import { AuthContext } from '../contexts';

export const MetricsPage = () => {
	const [Metrics, setMetrics] = useState(null);

	const { authencated, token } = useContext(AuthContext);

	const [Authencated] = authencated;

	const [Token] = token;

	function fetchMetrics() {
		fetch(
			'https://backend.breakingpoint.software/metrics?token=' + Token
			// 'http://localhost:3001/metrics?token=' + Token
		).then(async (data) => {
			const { metrics } = await data.json();

			setMetrics(metrics);
		});
	}

	useEffect(() => {
		if (!Token) return;

		fetchMetrics();
	}, [Authencated, Token]);

	return (
		<DashboardLayout>
			<div>
				<div style={{ padding: '15px' }}>
					<Card
						style={{
							backgroundColor: 'rgb(15, 15, 15)',
							borderColor: 'rgb(15, 15, 15)',
						}}
					>
						<Card.Body
							style={{
								backgroundColor: 'rgb(15, 15, 15)',
								borderColor: 'rgb(15, 15, 15)',
							}}
						>
							<div
								style={{
									display: 'flex',
								}}
							>
								<Card.Title>Metrics</Card.Title>
							</div>

							{Metrics && (
								<div style={{ paddingTop: '15px' }}>
									<div className='row'>
										<div className='col-md-3'>
											<Card
												body
												style={{
													textAlign: 'center',
												}}
											>
												<div>
													<Card.Title
														style={{
															margin: '0',
															padding: '0',
															fontSize: '25px',
														}}
													>
														Total Clients
													</Card.Title>

													<p
														style={{
															margin: '0',
															padding: '0',
															fontSize: '35px',
															fontWeight: '600',
															paddingTop: '5px',
														}}
													>
														{Metrics['clientCount']}
													</p>
												</div>
											</Card>
										</div>
									</div>
								</div>
							)}
						</Card.Body>
					</Card>
				</div>
			</div>
		</DashboardLayout>
	);
};
